import LocalTrainingRepository from '@/shared/http/repositories/socialProject/localtraining'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import NewRegister from '@/components/layout/NewRegister/newRegister.vue'
import LocalTraining from '@/shared/models/localTraining'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'

export default {
  name: 'NovoLocalDeTreinamento',

  components: {
    [BreadCrumb.name]: BreadCrumb,
    [NewRegister.name]: NewRegister
  },

  data: () => ({
    placeholder: 'Nome',
    title: 'Local de Treinamento',
    localTraining: new LocalTraining(),
    isNew: true
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Locais de treinamento',
            href: this.$router.resolve({ name: 'LocalTraining' }).href
          },
          {
            text: 'Novo local de treinamento',
            active: true
          }
        ]
      }
    }
  },

  methods: {
    create(localTraining) {
      loading.push()
      LocalTrainingRepository.Create(localTraining)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          toast.success('Local de Treinamento criado com sucesso', 'NOVO LOCAL DE TREINAMENTO')
          Promise.resolve()
          return this.$router.replace({ name: 'LocalTraining' })
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao criar local de treinamento', 'ERRO')
        })
    }
  }
}